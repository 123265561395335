<template>
  <q-card
    class="my-card text-left q-ma-md q-pb-sm"
    style="margin-bottom: 100px"
  >
    <q-list>
      <q-item>
        <q-item-section>
          <q-item-label class="text-weight-bold">{{
            $t("Profile.settings")
          }}</q-item-label>
        </q-item-section>
      </q-item>

      <q-item class="q-ml-md">
        <q-item-section
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
          "
          class="q-ma-none q-pa-none flex row"
        >
          <q-item-label
            style="text-decoration: underline"
            class="cursor-pointer"
            @click="dialogPrivacyPolicy = true"
            >{{ $t("Profile.languages") }}</q-item-label
          >
        </q-item-section>

        <q-item-section avatar> <LanguageSwitcher /> </q-item-section>
      </q-item>

      <q-item class="q-ml-md">
        <q-item-section class="q-ma-none q-pa-none">
          <q-item-label
            style="text-decoration: underline"
            class="cursor-pointer"
            @click="dialogPrivacyPolicy = true"
            >Dark Mode</q-item-label
          >
        </q-item-section>
        <q-item-section avatar>
          <div class="mode-toggle" @click="modeToggle" :class="darkDark">
            <div class="toggle" @change="updateDarkMode()">
              <div id="dark-mode" type="checkbox"></div>
            </div>
          </div>
        </q-item-section>
      </q-item>
    </q-list>
  </q-card>
</template>

<script>
import { defineAsyncComponent } from "vue";
import settings from "../../../../mixins/settings";
import darkmode from "../../../../mixins/darkmode";

const LanguageSwitcher = defineAsyncComponent(() =>
  import("../../../shared/components/LanguageSwitcher.vue")
);

export default {
  mixins: [settings, darkmode],
  components: {
    LanguageSwitcher,
  },
};
</script>

<style lang="scss" scoped>
.q-btn {
  border-radius: 25px 25px 25px 0px;
  height: 30px;
  text-transform: capitalize;
  width: 40%;
  max-width: 150px;
  color: white;
  background-color: v-bind("settings.color_primary");
}

.q-card {
  border-radius: 25px 25px 25px 0px;
}

select {
  border-radius: 25px 25px 25px 0px;
  min-width: 100px;
  padding-left: 5px;
  border: 1px solid v-bind("settings.color_primary");
}
</style>
